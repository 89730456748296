import { createStore } from "vuex";
// import axios from "axios";
import config from "./config";
// import router from "../router";

// const transport = axios.create({
//   withCredentials: true,
// });

import user from "./user";
import addresses from "./addresses";
import settings from "./settings";

export default createStore({
  state: {
    config: config,
    laguage: "de",
    loading: 0,
    errors: [],
  },
  getters: {
    config: (state) => state.config,
    laguage: (state) => state.laguage,
    loading: (state) => state.loading,
    errors: (state) => state.errors,
  },
  mutations: {
    setLoading(state, payload) {
      state.loading = state.loading + payload;
    },
    resetLoading(state) {
      state.loading = 0;
    },
    addError(state, payload) {
      state.errors.push(payload);
    },
  },
  actions: {
    addLoading({ commit }, load) {
      commit("setLoading", load);
    },
    addError({ commit }, e) {
      commit("addError", e);
    },
  },
  modules: {
    user,
    addresses,
    settings,
  },
});
