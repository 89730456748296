export function phone(address) {
  let phone = "";
  if (address.tel) {
    if (address.tel_int_area_code) {
      phone = "+" + address.tel_int_area_code.replace(/\s/g, "").replace(/\+/g, "");
      if (address.tel_city_area_code) {
        phone += address.tel_city_area_code.replace(/\s/g, "").replace(/^0+/, "");
      }
    } else if (address.tel_city_area_code) {
      phone = address.tel_city_area_code.replace(/\s/g, "");
    }
    phone += address.tel.replace(/\s/g, "");
  }
  return phone;
}

export function mobile(address) {
  let mobile = "";
  if (address.mobile) {
    if (address.mobile_int_area_code) {
      mobile = "+" + address.mobile_int_area_code.replace(/\s/g, "").replace(/\+/g, "");
      if (address.mobile_area_code) {
        mobile += address.mobile_area_code.replace(/\s/g, "").replace(/^0+/, "");
      }
    } else if (address.tel_int_area_code) {
      mobile = "+" + address.tel_int_area_code.replace(/\s/g, "").replace(/\+/g, "");
      if (address.mobile_area_code) {
        mobile += address.mobile_area_code.replace(/\s/g, "").replace(/^0+/, "");
      }
    } else if (address.mobile_area_code) {
      mobile = address.mobile_area_code.replace(/\s/g, "");
    }
    mobile += address.mobile.replace(/\s/g, "");
  }
  return mobile;
}

export function fax(address) {
  let fax = "";
  if (address.fax) {
    if (address.tel_int_area_code) {
      fax = "+" + address.tel_int_area_code.replace(/\s/g, "").replace(/\+/g, "");
      if (address.tel_city_area_code) {
        fax += address.tel_city_area_code.replace(/\s/g, "").replace(/^0+/, "");
      }
    } else if (address.tel_city_area_code) {
      fax = address.tel_city_area_code.replace(/\s/g, "");
    }
    fax += address.fax.replace(/\s/g, "");
  }
  return fax;
}

export function website(website) {
  return website
    ? website.startsWith("http")
      ? website.replace(/\s/g, "")
      : "https://" + website.replace(/\s/g, "")
    : "";
}

export function getDisplayName(address) {
  if (typeof address !== "undefined" && address !== null) {
    if (address.name !== null && address.name?.length > 0) {
      return address.name;
    } else if (address.forename && address.surname) {
      return address.forename + " " + address.surname;
    } else if (address.forename) {
      return address.forename;
    } else if (address.surname) {
      return address.surname;
    } else {
      return "";
    }
  } else {
    return "";
  }
}
