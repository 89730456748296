<template>
  <navigation @navopen="navopen" @navclose="navclose" />
  <main class="main" ref="main" :style="mainStyle">
    <router-view />
    <!-- <router-view v-slot="{ Component }">
      <Suspense>
        <template #default>
          <component :is="Component" />
        </template>
        <template #fallback><div>Loading...</div></template>
      </Suspense>
    </router-view> -->
    <div
      v-if="loading > 0"
      class="fixed-top d-flex flex-column justify-content-center align-items-center w-100 vh-100 bg-dark"
    >
      <div class="spinner-border text-light global-loader" role="status"></div>
    </div>
  </main>
</template>

<script>
// import { useI18n } from "vue-i18n";
import navigation from "@/components/navigation/main";
import { computed, reactive, ref } from "vue";
import { useStore } from "vuex";
export default {
  components: { navigation },
  setup() {
    // const { t } = useI18n();
    const main = ref(null);
    const store = useStore();
    if (store.getters.jwt) {
      store.dispatch("axiosAuthorization");
    }
    const mainStyle = reactive({
      // transform: "translateX(0)",
      marginRight: 0,
    });
    function navopen(width) {
      if (main.value.offsetWidth > 768) {
        mainStyle.marginRight = width + "px";
        // console.log(width);
      }
    }
    function navclose() {
      mainStyle.marginRight = 0;
    }

    document.documentElement.setAttribute("lang", process.env.VUE_APP_I18N_LOCALE);

    return {
      // t,
      loading: computed(() => store.getters.loading),
      mainStyle,
      main,
      navopen,
      navclose,
    };
  },
};
</script>

<style lang="scss">
@import "~@/styles/theme.scss";
</style>
