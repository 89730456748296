import axios from "axios";
import { ref } from "vue";
import { useStore } from "vuex";
import storeConfig from "@/store/config";
export function useApiQuery(options) {
  const error = ref(null);
  const store = useStore();
  if (typeof options === "undefined") {
    options = {};
  }
  if (typeof options.url === "undefined") {
    options.url = "";
  }
  if (typeof options.method === "undefined") {
    options.method = "get";
  }
  if (typeof options.defaultVal === "undefined") {
    options.defaultVal = [];
  }
  if (typeof options.params === "undefined") {
    options.params = {};
  }
  if (typeof options.showLoading === "undefined") {
    options.showLoading = true;
  }
  const result = ref(options.defaultVal);
  const query = async (extendOptions) => {
    if (typeof extendOptions !== "undefined") {
      Object.assign(options, extendOptions);
    }
    try {
      if (options.showLoading) {
        await store.dispatch("addLoading", 1);
      }
      if (options.url === "") {
        throw new Error("No url specified");
      } else {
        let { data } = await axios[options.method](storeConfig.apiBaseUrl + options.url, options.params);
        if (typeof options.pfn === "function") {
          data = options.pfn(data);
        }
        result.value = data;
      }
    } catch (e) {
      result.value = options.defaultVal;
      error.value = e;
    } finally {
      if (options.showLoading) {
        await store.dispatch("addLoading", -1);
      }
    }
  };
  return {
    result,
    error,
    query,
  };
}
