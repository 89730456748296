import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
// import { createI18n } from "vue-i18n";
// import { createI18n } from "vue-i18n/index";
// import messages from "./messages.js";
import { i18n } from "./util/i18n";
import Toast from "vue-toastification";
// import OpenLayersMap from "vue3-openlayers";
// import "vue3-openlayers/dist/vue3-openlayers.css";

// const i18n = createI18n({
//   legacy: false,
//   locale: process.env.VUE_APP_I18N_LOCALE,
//   fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE,
//   // warnHtmlInMessage: false,
//   // warnHtmlMessage: false,
//   // escapeParameter: false,
//   messages,
// });

createApp(App)
  .use(store)
  .use(router)
  .use(i18n)
  .use(Toast, {
    // see: https://maronato.github.io/vue-toastification/
    // better: https://github.com/Maronato/vue-toastification/tree/next
    position: "top-center",
    timeout: 2500,
    closeOnClick: true,
    pauseOnFocusLoss: true,
    pauseOnHover: true,
    draggable: true,
    draggablePercent: 0.6,
    hideProgressBar: true,
  })
  // .use(OpenLayersMap)
  .mount("#app");
