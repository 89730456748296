import { createRouter, createWebHistory } from "vue-router";
import store from "../store";
import { nav } from "../util/i18n";

const routes = [
  {
    path: "/",
    name: "Dashboard",
    component: () => import(/* webpackChunkName: "Dashboard" */ "../views/Dashboard.vue"),
    meta: { protected: true, title: nav.dashboard },
  },
  {
    path: "/login",
    name: "Login",
    component: () => import(/* webpackChunkName: "Login" */ "../views/user/Login.vue"),
    meta: { title: nav.login },
  },
  {
    path: "/user",
    name: "UserList",
    component: () => import(/* webpackChunkName: "UserList" */ "../views/user/List.vue"),
    meta: { protected: true, title: nav.user_list },
  },
  {
    path: "/user/create",
    name: "UserCreate",
    component: () => import(/* webpackChunkName: "UserCreate" */ "../views/user/Create.vue"),
    meta: { protected: true, title: nav.user_create },
  },
  {
    path: "/user/edit/:id",
    name: "UserEdit",
    component: () => import(/* webpackChunkName: "UserEdit" */ "../views/user/Edit.vue"),
    meta: { protected: true, title: nav.user_edit },
  },
  {
    path: "/user/delete/:id",
    name: "UserDelete",
    component: () => import(/* webpackChunkName: "UserDelete" */ "../views/user/Delete.vue"),
    meta: { protected: true, title: nav.user_delete },
  },
  {
    path: "/legal",
    name: "Legal",
    component: () => import(/* webpackChunkName: "Legal" */ "../views/Legal.vue"),
    meta: { protected: false, title: nav.legal },
  },
  {
    path: "/addresses",
    name: "Addresses",
    component: () => import(/* webpackChunkName: "Addresses" */ "../views/addresses/Addresses.vue"),
    meta: { protected: true, title: nav.addresses },
  },
  {
    path: "/addresses/create",
    name: "AddressCreate",
    component: () => import(/* webpackChunkName: "AddressCreate" */ "../views/addresses/Create.vue"),
    meta: { protected: true, title: nav.addresses_create },
  },
  {
    path: "/addresses/edit/:id",
    name: "AddressEdit",
    component: () => import(/* webpackChunkName: "AddressEdit" */ "../views/addresses/Edit.vue"),
    meta: { protected: true, title: nav.addresses_edit },
  },
  {
    path: "/addresses/addresscontacts/:id",
    name: "Addresscontacts",
    component: () => import(/* webpackChunkName: "Addresscontacts" */ "../views/addresses/Addresscontacts.vue"),
    meta: { protected: true, title: nav.addresscontacts },
  },
  {
    path: "/addresses/contactof/:id",
    name: "Contactof",
    component: () => import(/* webpackChunkName: "Contactof" */ "../views/addresses/Contactof.vue"),
    meta: { protected: true, title: nav.contactof },
  },
  {
    path: "/addresses/gigs/:id",
    name: "Gigs",
    component: () => import(/* webpackChunkName: "Gigs" */ "../views/addresses/Gigs.vue"),
    meta: { protected: true, title: nav.gigs },
  },
  {
    path: "/addresses/gigsvenue/:id",
    name: "Gigsvenue",
    component: () => import(/* webpackChunkName: "Gigsvenue" */ "../views/addresses/Gigsvenue.vue"),
    meta: { protected: true, title: nav.gigs },
  },
  {
    path: "/addresses/jobs/:id",
    name: "Jobs",
    // @see https://github.com/vuejs/rfcs/blob/master/active-rfcs/0028-router-active-link.md
    children: [
      {
        path: "",
        name: "Jobs",
      },
      {
        path: ":jobid",
        name: "Jobs",
        component: () => import(/* webpackChunkName: "Jobs" */ "../views/addresses/Jobs.vue"),
        meta: { protected: true, title: nav.gigs },
      },
    ],
    component: () => import(/* webpackChunkName: "Jobs" */ "../views/addresses/Jobs.vue"),
    meta: { protected: true, title: nav.gigs },
  },
  {
    path: "/addresses/tours/:id",
    name: "Tours",
    component: () => import(/* webpackChunkName: "Tours" */ "../views/addresses/Tours.vue"),
    meta: { protected: true, title: nav.tours },
  },
  {
    path: "/addresses/availabilities/:id",
    name: "Availabilities",
    component: () => import(/* webpackChunkName: "Availabilities" */ "../views/addresses/Availabilities.vue"),
    meta: { protected: true, title: nav.availabilities },
  },
  {
    path: "/addresses/festivaldata/:id",
    name: "Festivaldata",
    component: () => import(/* webpackChunkName: "Festivaldata" */ "../views/addresses/Festivaldata.vue"),
    meta: { protected: true, title: nav.festivaldata },
  },
  {
    path: "/addresses/geocode",
    name: "AddressesGeocode",
    component: () => import(/* webpackChunkName: "AddressesGeocode" */ "../views/addresses/Geocode.vue"),
    meta: { protected: true, title: "Geolocation" },
  },
  {
    path: "/addresses/stages/:id",
    name: "Stages",
    component: () => import(/* webpackChunkName: "Stages" */ "../views/addresses/Stages.vue"),
    meta: { protected: true, title: nav.stages },
  },
  {
    path: "/gig/:id",
    name: "Gig",
    component: () => import(/* webpackChunkName: "Gig" */ "../views/Gig.vue"),
    meta: { protected: true, title: nav.gigs },
  },
  {
    path: "/roster/:year?/:month?",
    name: "Roster",
    component: () => import(/* webpackChunkName: "Roster" */ "../views/Roster.vue"),
    meta: { protected: true, title: nav.roster },
  },
  {
    // path: "/jobs/list/:startyear?/:startmonth?/:startday?/:endyear?/:endmonth?/:endday?/:jobid?",
    path: "/jobs/list/:jobid?",
    name: "Joblist",
    component: () => import(/* webpackChunkName: "Joblist" */ "../views/jobs/List.vue"),
    meta: { protected: true, title: nav.jobs },
  },
  {
    path: "/:pathMatch(.*)*",
    name: "404",
    component: () => import(/* webpackChunkName: "404" */ "../views/404.vue"),
    meta: { title: nav.not_found },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  window.document.title =
    to.meta && to.meta.title ? to.meta.title + " - " + process.env.VUE_APP_APP_NAME : process.env.VUE_APP_APP_NAME;
  if (to.matched.some((record) => record.meta.protected)) {
    // console.log("Login", store.getters.jwt); -- never show jwt in concole!
    if (!store.getters.jwt || !localStorage.getItem("jwt")) {
      store.dispatch("logout");
      next({ name: "Login" });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
