export default {
  de: {
    nav: {
      dashboard: "Dashboard",
      login: "Login",
      user_create: "Benutzer hinzufügen",
      user_list: "Benutzer anzeigen",
      user_edit: "Benutzer bearbeiten",
      user_delete: "Benutzer löschen",
      legal: "Impressum & Datenschutz",
      addresses: "Adressen",
      addresses_create: "Adresse hinzufügen",
      addresses_edit: "Adressen bearbeiten",
      addresscontacts: "Kontakte",
      contactof: "Kontakt von",
      gigs: "Auftritte",
      tours: "Tourneen",
      jobs: "Aufträge",
      festivaldata: "Festival Daten",
      stages: "Bühnen",
      roster: "Roster & Plannings",
      availabilities: "Verfügbarkeiten",
      not_found: "404 - Seite nicht gefunden",
    },
    user: {
      user: "Benutzer",
      greetUser: "Hallo {name}",
      alreadyLoggedin1: "du bist derzeit angemeldet.",
      alreadyLoggedin2: "Melde dich ab.",
      email: "E-Mail",
      password: "Passwort",
      password_confirmation: "Passwort bestätigen",
      name: "Name",
      login: "Anmelden",
      loggedinUser: "Angemeldeter Benutzer",
      logout: "Abmelden",
      create: "Neuen Benutzer hinzufügen",
      isAdmin: "Administrator",
      role: "Rolle",
      deleteThisUser: "Diesen Benutzer löschen",
    },
    address: {
      del_address: "Adresse Löschen",
      address: "Adresse",
      name: "Name",
      person_name: "Name der Person",
      salutation: "Anrede",
      forename: "Vorname",
      surname: "Nachname",
      email: "E-Mail",
      description: "Bezeichnung",
      website: "Website",
      vat: "VAT ID",
      comment: "Kommentar",
      street: "Straße",
      plz: "PLZ",
      city: "Ort",
      country: "Land",
      postbox: "Postfach",
      person: "Person",
      company: "Firma",
      valuation: "Bewertung",
      tel_int_area_code: "Tel. int. Vorwahl (+)",
      tel_city_area_code: "Tel. Ortsvorwahl",
      tel: "Tel. Festnetz",
      mobile_int_area_code: "Mobil int. Vorwahl (+)",
      mobile_area_code: "Mobil Vorwahl",
      mobile: "Tel. Mobil",
      fax: "Fax",
      contacts: "Kontakte",
      delete_dialog: "Wollen Sie diese Adresse wirklich löschen?",
      delete_dialog_hint: "Alle Verknüpfungen werden ebenfalls gelöscht.",
      latest_edited_by: "Zuletzt bearbeitete Adressen durch",
      latest_created_by: "Zuletzt angelegte Adressen durch",
    },
    search: {
      name: "Name",
      surname: "Nachname",
      forename: "Vorname",
      email: "E-Mail",
      description: "Bezeichnung",
      street: "Straße",
      plz: "PLZ",
      postcode: "Postfach",
      city: "Stadt",
      website: "Website",
      tel: "Telefon",
      mobile: "Mobil",
      fax: "Fax",
      both: "Name oder Nachname",
      all: "alle Felder",
      noDataAvailable: "Keine Treffer",
      feeGt: "Gage größer / gleich",
    },
    error: {
      wrongCredentials: "Falsche Zugansdaten.",
      errorOccured: "Es ist ein Fehler aufgetreten.",
      invalidData: "Die übermittelten Daten waren ungültig.",
      error: "Fehler",
      notFound: "nicht gefunden",
      task_not_found: "Aufgabe nicht gefunden",
    },
    general: {
      show: "Anzeigen",
      edit: "Bearbeiten",
      change_assignment: "Verknüpfung Ändern",
      save: "Speichern",
      apply_changes: "Änderungen Übernehmen",
      cancel: "Abbrechen",
      delete: "Löschen",
      remove: "Entfernen",
      create: "Erstellen",
      new: "Erstellen",
      search: "Suche",
      add: "Hinzufügen",
      addas: "Hinzufügen als",
      link: "verknüpfen",
      linkas: "Verknüpfen als",
      link_contact: "Kontakt verknüpfen",
      link_tour: "Tournee verknüpfen",
      link_festival: "Festival verknüpfen",
      link_venue: "Venue verknüpfen",
      link_organizer: "Veranstalter verknüpfen",
      link_artist: "Künstler verknüpfen",
      link_stage: "Bühne verknüpfen",
      added: "hinzugefügt",
      edited: "bearbeitet",
      deleted: "gelöscht",
      removed: "entfernt",
      successfully: "erfolgreich",
      update: "aktualisieren",
      person: "Person",
      company: "Firma",
      categories: "Kategorien",
      hits: "Treffer",
      reset: "Zurücksetzen",
      editor: "Bearbeiter",
      searchtype: "Suche nach",
      noPermission: "Sie haben keine Berechtigung diese Aktion auszuführen.",
      city: "Ort",
      country: "Land",
      date: "Datum",
      date_start: "Datum von",
      date_end: "Datum bis",
      start_at: "Datum von",
      end_at: "Datum bis",
      status: "Status",
      comment: "Kommentar",
      name: "Name",
      gig: "Auftritt",
      venue: "Venue",
      tour: "Tournee",
      festival: "Festival",
      organizer: "Veranstalter",
      load_more_data: "Mehr Daten laden",
      job: "Auftrag",
      jobs: "Aufträge",
      artist: "Künstler",
      area: "Bereich",
      terms_of_contract: "Vertragsbedingungen",
      dealsheet_control: "Dealsheet Control",
      currency: "Währung",
      mwstpercent: "MwSt. in %",
      mwst: "MwSt.",
      filter: "Filtern",
      filter_by_date: "nach Datum Filtern",
      search_fulltext: "Volltextsuche",
      all_users: "alle Benutzer",
      dashboard: "Dashboard",
      stage: "Bühne",
      quickfilter: "Schnellfilter",
      quickfilter_by: "Schnellfilter nach",
      availability: "Verfügbarkeit",
      availablilities: "Verfügbarkeiten",
      task: "Aufgabe",
      tasks: "Aufgaben",
      task_add: "Aufgabe hinzufügen",
      tasks_created_by_me: "Aufgaben von mir",
      tasks_assigned_to_me: "Aufgaben für mich",
      current_task: "Aktuelle Aufgabe",
      description: "Beschreibung",
      open: "Offen",
      closed: "Geschlossen",
      close: "Schließen",
      inprogress: "In Bearbeitung",
      resolved: "Erledigt",
      reopened: "Wiedereröffnet",
      created_at: "Erstellt am",
      type: "Typ",
      history: "Verlauf",
      from: "Von",
      save_as_csv: "Als CSV speichern",
    },
    category: {
      add: "Kategorie hinzufügen",
      delete: "Kategorie entfernen",
      edit: "Kategorie bearbeiten",
    },
    datatbl: {
      name: "Name",
      email: "E-Mail",
      role: "Rolle",
      pagingInfo: "Zeige {0}-{1} von {2}",
      pageSizeChangeLabel: "Zeilen:",
      gotoPageLabel: "Gehe zu Seite:",
      noDataAvailable: "Keine Daten",
    },
    gig: {
      add: "Auftritt hinzufügen",
      delete: "Auftritt Löschen",
      delete_confirm_inquire: "Sind Sie sicher, dass Sie diesen Auftritt löschen möchten?",
    },
    job: {
      add: "Auftrag hinzufügen",
      delete: "Auftrag Löschen",
      delete_confirm_inquire: "Sind Sie sicher, dass Sie diesen Auftrag löschen möchten?",
      fee: "Gage/Honorar",
      production_costs: "Produktionskosten",
      meal_allowance: "Essenspauschale",
      promomaterial: "Promomaterial",
      provision: "Provision",
      deal_with_organizer: "Deal mit dem Veranstalter",
      main_deal: "Main Deal",
      special_agreements: "Deal mit dem Künstlermanagement",
      terms_of_payment: "Zahlungsbedingungen",
      tickets: "Tickets",
      tickets_VVK: "Tickets VVK",
      tickets_AK: "Tickets AK",
      tickets_reduced: "Tickets ermäßigt",
      tickets_percents: "Prozente",
      tickets_amount: "Betrag",
      tickets_breakeven: "Breakeven (Veranstalter)",
      capacity_seated: "Kapazität Sitzplätze",
      capacity_standing: "Kapazität Stehplätze",
      gross_potential_seated: "Brutto-Potenzial Sitzplätze",
      gross_potential_standing: "Brutto-Potenzial Stehplätze",
      sum_netto: "Summe netto",
    },
    tour: {
      add: "Tournee hinzufügen",
      delete: "Tournee entfernen",
      delete_confirm_inquire: "Sind Sie sicher, dass Sie diese Tournee entfernen möchten?",
    },
    stage: {
      stage: "Bühne",
      capacity_seated: "Sitzplätze",
      capacity_standing: "Stehplätze",
      width: "Breite",
      height: "Höhe",
      depth: "Tiefe",
      clear_height: "lichte Höhe",
      electricity: "Strom",
      distance_to_load: "Entfernung zum Laden",
      delete: "Bühne entfernen",
      delete_confirm_inquire: "Sind Sie sicher, dass Sie diese Bühne entfernen möchten?",
      no_stages: "Das Venue verfügt über keine Einträge für Bühnen",
    },
    availability: {
      add: "Verfügbarkeit hinzufügen",
      delete: "Verfügbarkeit entfernen",
      delete_confirm_inquire: "Sind Sie sicher, dass Sie diese Verfügbarkeit entfernen möchten?",
      no_availabilities: "Keine Verfügbarkeiten",
      from: "Von",
      to: "Bis",
      comment: "Kommentar",
      type: "Typ",
      available: "Verfügbar",
      unavailable: "Nicht verfügbar",
      tourperiod: "Tourzeitraum",
      alwaysShowArtistWithAvailability: "Künstler mit nur Verfügbarkeiten anzeigen",
    },
    weekdays: {
      Sunday: "Sonntag",
      Monday: "Montag",
      Tuesday: "Dienstag",
      Wednesday: "Mittwoch",
      Thursday: "Donnerstag",
      Friday: "Freitag",
      Saturday: "Samstag",
    },
  },
};
