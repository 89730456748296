import axios from "axios";
import config from "./config";
import router from "../router";

const state = {
  user: localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : null,
  editUser: null,
  users: [],
  jwt: localStorage.getItem("jwt") || null,
};

const getters = {
  user: (state) => state.user,
  editUser: (state) => state.editUser,
  jwt: (state) => state.jwt,
  users: (state) => state.users,
  activeUsers: (state) => state.users.filter((user) => user.status === "active"),
};

const mutations = {
  setUser(state, payload) {
    // console.log(payload);
    state.user = payload;
    // if (state.user?.settings && typeof state.user.settings === "string") {
    //   state.user.settings = JSON.parse(state.user.settings);
    // }

    // if (payload.settings) {
    // localStorage.setItem("settings", JSON.stringify(payload.settings));
    // state.user.settings = JSON.parse(payload.settings);
    // }
    // payload.settings = JSON.parse(payload.settings) || {};
  },
  SET_EDIT_USER(state, payload) {
    state.editUser = payload;
  },
  setJwt(state, payload) {
    state.jwt = payload;
  },
  SET_USERS(state, payload) {
    state.users = payload.map((obj) => {
      if (obj.role === 0) {
        obj.roleDescription = "admin";
      } else {
        obj.roleDescription = "user";
      }
      return obj;
    });
  },
};
const actions = {
  login({ commit }, { email, password }) {
    return new Promise((resolve, reject) => {
      commit("setLoading", 1);
      axios
        .post(config.apiBaseUrl + "login", {
          email: email,
          password: password,
        })
        .then((response) => {
          commit("setUser", response.data.user);
          localStorage.setItem("user", JSON.stringify(response.data.user));
          commit("setJwt", response.data.token);
          localStorage.setItem("jwt", response.data.token);
          axios.defaults.headers.common["Authorization"] = `Bearer ${response.data.token}`;
          commit("setLoading", -1);
          resolve(response);
        })
        .catch((e) => {
          commit("addError", e);
          commit("setUser", null);
          commit("setJwt", null);
          localStorage.removeItem("user");
          localStorage.removeItem("jwt");
          commit("setLoading", -1);
          if (e.response) {
            reject(e.response);
          } else {
            reject(e);
          }
        });
    });
  },
  logout({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .post(config.apiBaseUrl + "logout")
        .then((response) => {
          commit("setUser", null);
          commit("setJwt", null);
          localStorage.removeItem("user");
          localStorage.removeItem("jwt");
          delete axios.defaults.headers.common["Authorization"];
          router.push({ name: "Login" });
          // console.log(response);
          resolve(response.message);
        })
        .catch((error) => {
          // console.log(error.response);
          commit("setUser", null);
          commit("setJwt", null);
          localStorage.removeItem("user");
          localStorage.removeItem("jwt");
          delete axios.defaults.headers.common["Authorization"];
          if (error.response?.status === 401) {
            router.push({ name: "Login" });
            resolve("Logged out");
          }
          router.push({ name: "Login" });
          reject(error);
        });
      // router.push({ name: "Login" });
    });
  },
  axiosAuthorization({ state }) {
    axios.defaults.headers.common["Authorization"] = `Bearer ${state.jwt}`;
  },
  async loadUsers({ commit, dispatch }) {
    try {
      commit("setLoading", 1);
      const users = await axios.get(config.apiBaseUrl + "user");
      commit("SET_USERS", users.data);
      commit("setLoading", -1);
      return users;
    } catch (error) {
      commit("SET_USERS", []);
      if (error.response?.data?.statusCode === 401 || error.response?.status === 401) {
        await dispatch("logout");
      }
      commit("setLoading", -1);
      return false;
    }
  },
  async loadUser({ commit, dispatch }, id) {
    try {
      commit("setLoading", 1);
      const user = await axios.get(config.apiBaseUrl + "user/" + id);
      commit("SET_EDIT_USER", user.data);
      commit("setLoading", -1);
      return user;
    } catch (error) {
      commit("SET_EDIT_USER", null);
      if (error.response?.data?.statusCode === 401 || error.response?.status === 401) {
        await dispatch("logout");
      }
      commit("setLoading", -1);
      return false;
    }
  },
  // async createUser({ commit, dispatch }, data) {
  //   console.log("data", data);
  //   const res = await axios.post(config.apiBaseUrl + "register", data);
  //   return res.data;
  //   // try {
  //   //   commit("setLoading", 1);
  //   //   const res = await axios.post(config.apiBaseUrl + "register", data);
  //   //   commit("setLoading", -1);
  //   //   return res.data;
  //   // } catch (error) {
  //   //   commit("setLoading", -1);
  //   //   console.log("store", error.response.data);
  //   //   return error;
  //   // }
  // },
  async updateUser({ commit }, data) {
    try {
      commit("setLoading", 1);
      // console.log(id);
      const res = await axios.put(config.apiBaseUrl + "user/" + data.id, data);
      commit("setLoading", -1);
      return res.data;
    } catch (error) {
      commit("setLoading", -1);
      return error;
    }
  },

  // async setUser({ commit }, data) {
  //   console.log("update user", data);
  //   try {
  //     commit("setUser", data);
  //     const res = await axios.put(config.apiBaseUrl + "user/" + data.id, data);
  //     console.log("update user", res.data);
  //     return res.data;
  //   } catch (error) {
  //     return error;
  //   }
  // },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
