import axios from "axios";
import config from "./config";

const state = {
  addresses: [],
  address: {},
  currentSearchName: "",
  currentCategoryId: 0,
  currentSearchCompany: false,
  currentSearchPerson: false,
  selectedSearchType: "name",
};

const getters = {
  addresses: (state) => state.addresses,
  address: (state) => state.address,
  currentSearchName: (state) => state.currentSearchName,
  currentCategoryId: (state) => state.currentCategoryId,
  currentSearchCompany: (state) => state.currentSearchCompany,
  currentSearchPerson: (state) => state.currentSearchPerson,
  selectedSearchType: (state) => state.selectedSearchType,
};

const mutations = {
  SET_ADDRESSES(state, payload) {
    state.addresses = payload;
  },
  SET_ADDRESS(state, payload) {
    state.address = payload;
  },
  SET_CURRENT_SEARCH_NAME(state, payload) {
    state.currentSearchName = payload;
  },
  SET_CURRENT_CATEGORY_ID(state, payload) {
    state.currentCategoryId = payload;
  },
  SET_CURRENT_SEARCH_COMPANY(state, payload) {
    state.currentSearchCompany = payload;
  },
  SET_CURRENT_SEARCH_PERSON(state, payload) {
    state.currentSearchPerson = payload;
  },
  SET_SELECTED_SEARCH_TYPE(state, payload) {
    state.selectedSearchType = payload;
  },
};
const actions = {
  // async loadAddresses({ commit, dispatch, rootState }) {
  async loadAddresses({ commit, dispatch }) {
    try {
      commit("setLoading", 1);
      const addresses = await axios.get(config.apiBaseUrl + "addresses");
      commit("SET_ADDRESSES", addresses.data);
      commit("setLoading", -1);
      return addresses;
    } catch (error) {
      commit("SET_ADDRESSES", []);
      if (error.response?.data?.statusCode === 401 || error.response?.status === 401) {
        await dispatch("logout");
        // console.log(error.response);
        // toast.error(rootState.msg[rootState.laguage]["errorUnauthorized"]);
      } else {
        // toast.error(error.message);
      }
      commit("setLoading", -1);
      return false;
    }
  },
  async loadAddress({ commit }, id) {
    try {
      commit("setLoading", 1);
      const job = await axios.get(config.apiBaseUrl + "addresses/" + id);
      commit("SET_ADDRESS", job.data);
      commit("setLoading", -1);
      return job.data;
    } catch (error) {
      commit("SET_ADDRESS", {});
      // toast.error(error.message);
      commit("setLoading", -1);
      return error;
    }
  },

  async setCurrentSearchName({ commit }, name) {
    commit("SET_CURRENT_SEARCH_NAME", name);
  },
  async setCurrentCategoryId({ commit }, id) {
    commit("SET_CURRENT_CATEGORY_ID", id);
  },
  async setCurrentSearchCompany({ commit }, data) {
    commit("SET_CURRENT_SEARCH_COMPANY", data);
  },
  async setCurrentSearchPerson({ commit }, data) {
    commit("SET_CURRENT_SEARCH_PERSON", data);
  },
  async setSelectedSearchType({ commit }, data) {
    commit("SET_SELECTED_SEARCH_TYPE", data);
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
