// import axios from "axios";
// import config from "./config";
// import router from "../router";

const state = {
  settings: localStorage.getItem("mbsettings") ? JSON.parse(localStorage.getItem("mbsettings")) : {},
};

const getters = {
  settings: (state) => state.settings,
};

const mutations = {
  SET_SETTINGS(state, payload) {
    // update settings keeping the old ones
    state.settings = { ...state.settings, ...payload };
  },
};
const actions = {
  async updateSettings({ commit }, data) {
    try {
      commit("SET_SETTINGS", data);
      localStorage.setItem("mbsettings", JSON.stringify(state.settings));
    } catch (error) {
      return error;
    }
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
