import messages from "../messages.js";
import { createI18n } from "vue-i18n/index";
export const i18n = createI18n({
  legacy: false,
  // globalInjection: true,
  locale: process.env.VUE_APP_I18N_LOCALE,
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE,
  messages,
});

export const nav = messages[process.env.VUE_APP_I18N_LOCALE]["nav"];

export function setDocumentLang(lang) {
  document.documentElement.lang = lang;
}

export function setDocumentTitle(title) {
  document.title = title;
}
