<template>
  <Teleport to="#modals">
    <transition name="modal-animation">
      <div v-if="modalActive" class="modal fade show" style="display: block">
        <div :class="modalClass">
          <div class="modal-content">
            <div class="modal-header">
              <h3 class="modal-title"><slot name="header"> </slot></h3>
              <button @click="close" type="button" class="btn-close" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <slot name="body"> </slot>
            </div>
            <div class="modal-footer">
              <slot name="footer"> </slot>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </Teleport>
</template>

<script>
import { computed } from "@vue/runtime-core";
export default {
  props: ["modalActive", "modalCssClass"],
  emits: ["close"],
  setup(props, { emit }) {
    const close = () => {
      emit("close");
    };
    return {
      close,
      modalClass: computed(() => {
        return typeof props.modalCssClass !== "undefined"
          ? "modal-dialog modal-dialog-centered modal-dialog-scrollable " + props.modalCssClass
          : "modal-dialog modal-dialog-centered modal-dialog-scrollable";
      }),
    };
  },
};
</script>

<style lang="scss" scoped>
.modal-animation-enter-active,
.modal-animation-leave-active {
  transition: opacity 0.3s cubic-bezier(0.52, 0.02, 0.19, 1.02);
}
.modal-animation-enter-from,
.modal-animation-leave-to {
  opacity: 0;
}
.modal-animation-enter-active .modal-content {
  transition: all 0.3s cubic-bezier(0.52, 0.02, 0.19, 1.02) 0.15s;
}
.modal-animation-leave-active .modal-content {
  transition: all 0.3s cubic-bezier(0.52, 0.02, 0.19, 1.02);
}
.modal-animation-enter-from .modal-content {
  opacity: 0;
  transform: scale(0.8);
}
.modal-animation-leave-to .modal-content {
  transform: scale(0.8);
}
</style>
